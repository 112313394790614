import { ossImg } from "utils/oss"

const CN = () => ({
  seo: {
    title: 'ESG 报告及政策 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生坚持以人为本,通过完善的用人政策、员工赋能与福利体系,营造安全,健康,公平,温暖的工作环境.',
  },
  breadcrumb: '首页 / ESG / ESG 报告及政策',
  tableHead: {
    classes: '议题',
    content: '实践'
  },
  tableData: [
    {
      classes: 'ESG报告',
      content: [
        {
          name: '万物新生2023年环境、社会、管治报告',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F2023%E5%B9%B4%E5%BA%A6%E7%8E%AF%E5%A2%83%E3%80%81%E7%A4%BE%E4%BC%9A%E5%8F%8A%E7%AE%A1%E6%B2%BB%E6%8A%A5%E5%91%8A.pdf')
        },
        {
          name: '万物新生2022年环境、社会、管治报告',
          url: ossImg('/downloads/2022_ESG_Report_CN.pdf')
        },
        {
          name: '万物新生2021年环境、社会、管治报告',
          url: ossImg('/downloads/2021_ESG_Report_CN_2.pdf')
        },
        {
          name: '万物新生2020年环境、社会、管治报告',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F2020%E5%B9%B4%E5%BA%A6%E7%8E%AF%E5%A2%83%E3%80%81%E7%A4%BE%E4%BC%9A%E5%8F%8A%E7%AE%A1%E6%B2%BB%E6%8A%A5%E5%91%8A.pdf')
        },
      ]
    },
    {
      classes: '环境',
      content: [
        {
          name: '环境政策',
          url: ossImg('/downloads/ATRenew_Environmental_Policy_2022_CN.pdf'),
        },
      ]
    },
    {
      classes: '人权',
      content: [
        {
          name: '人权政策',
          url: ossImg('/downloads/ESG_Human_Rights_CN.pdf')
        },
      ]
    },
    {
      classes: '公司治理',
      content: [
        {
          name: '管理层简介',
          url: 'https://ir.atrenew.com/corporate-governance/management'
        },
        {
          name: '董事会',
          url: 'https://ir.atrenew.com/corporate-governance/board-of-directors'
        },
        {
          name: '审计委员会章程',
          url: 'https://ir.atrenew.com/static-files/8bf87613-dbaa-4678-8863-5693027cf3ae'
        },
        {
          name: '薪酬委员会章程',
          url: 'https://ir.atrenew.com/static-files/74cc47ea-1aca-4c89-a419-b3db1b862f04'
        },
        {
          name: '提名及公司治理委员会章程',
          url: 'https://ir.atrenew.com/static-files/9d5fbc88-1268-434d-ad4c-cf2b5f830544'
        },
        {
          name: '高管薪酬追回政策',
          url: ossImg('/downloads/ESG_Clawback.pdf')
        },
        {
          name: '万物新生ESG委员会运作机制',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2ESGC%E8%BF%90%E4%BD%9C%E6%9C%BA%E5%88%B6_2024.pdf')
        }
      ]
    },
    {
      classes: '商业道德',
      content: [
        {
          name: '商业行为和道德准则',
          url: 'https://www.sec.gov/Archives/edgar/data/1838957/000119312521176950/d893059dex991.htm'
        },
        {
          name: '廉洁自律准则',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2%E5%BB%89%E6%B4%81%E8%87%AA%E5%BE%8B%E5%87%86%E5%88%99-202405.pdf'),
        },
        {
          name: '举报人保护与奖励制度',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2%E4%B8%BE%E6%8A%A5%E4%BA%BA%E4%BF%9D%E6%8A%A4%E4%B8%8E%E5%A5%96%E5%8A%B1%E5%88%B6%E5%BA%A6-202404.pdf'),
        },
        {name: '阳光诚信联盟成员'},
      ]
    },
    {
      classes: '可持续供应链',
      content: [
        {
          name: '供应商行为准则',
          url: ossImg('/downloads/ATRenew_Supplier_Code_of_Conduct_CN.pdf')
        },
      ]
    },
    {
      classes: '认证',
      content: [
        {
          name: 'ISO 14001 环境管理体系认证',
          url: ossImg('/downloads/ISO_14001.pdf')
        },
        {
          name: 'ISO 27001 信息安全管理体系认证',
          url: ossImg('/downloads/ISO_27001.pdf')
        },
        {
          name: 'ISO 9001 质量管理体系认证',
          url: ossImg('/downloads/ISO_9001.pdf')
        },
        {
          name: 'ISO 45001 职业健康安全管理体系',
          url: ossImg('/downloads/ISO_45001.pdf')
        },
        {
          name: 'ISO 20000 信息技术服务管理体系认证',
          url: ossImg('/downloads/ISO_20000.pdf')
        },
      ]
    },
    {
      classes: '负责任营销',
      content: [
        {
          name: '负责任营销政策',
          url: ossImg('/downloads/ATRenew_Responsible_Marketing_Policy.pdf')
        },
      ]
    },
  ]
})

const US = () => ({
  seo: {
    title: 'ESG Reports and Policies - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew respects employees\' rights and strives to provide a safe, healthy, and welcoming working environment.',
  },
  breadcrumb: 'Home / ESG / ESG Reports and Policies',
  tableHead: {
    classes: 'Topics',
    content: 'Practices'
  },
  tableData: [
    {
      classes: 'ESG Reports',
      content: [
        {
          name: 'ATRenew 2023 ESG Report',
          url: ossImg('/downloads/ATRenew_2023_Environmental_Social_and_Governance_Report.pdf')
        },
        {
          name: 'ATRenew 2022 ESG Report',
          url: ossImg('/downloads/2022_ESG_Report_EN.pdf')
        },
        {
          name: 'ATRenew 2021 ESG Report',
          url: ossImg('/downloads/2021_ESG_Report_EN_3.pdf')
        },
        {
          name: 'ATRenew 2020 ESG Report',
          url: ossImg('/downloads/ATRenew_2020_Environmental_Social_and_Governance_Report.pdf')
        },
      ]
    },
    {
      classes: 'Environment',
      content: [
        {
          name: 'Environmental Policy',
          url: ossImg('/downloads/ATRenew_Environmental_Policy_2022_EN.pdf'),
        },
      ]
    },
    {
      classes: 'Human Rights',
      content: [
        {
          name: 'Human Rights Policy',
          url: ossImg('/downloads/ESG_Human_Rights_CN.pdf')
        },
      ]
    },
    {
      classes: 'Corporate Governance',
      content: [
        {
          name: 'Profile of Management',
          url: 'https://ir.atrenew.com/corporate-governance/management'
        },
        {
          name: 'Board of Directors',
          url: 'https://ir.atrenew.com/corporate-governance/board-of-directors'
        },
        {
          name: 'Audit Committee Charter',
          url: 'https://ir.atrenew.com/static-files/8bf87613-dbaa-4678-8863-5693027cf3ae'
        },
        {
          name: 'Compensation Committee Charter',
          url: 'https://ir.atrenew.com/static-files/74cc47ea-1aca-4c89-a419-b3db1b862f04'
        },
        {
          name: 'Nominating and Corporate Governance Committee Charter',
          url: 'https://ir.atrenew.com/static-files/9d5fbc88-1268-434d-ad4c-cf2b5f830544'
        },
        {
          name: 'Executive Compensation Clawback Policy',
          url: ossImg('/downloads/ESG_Clawback.pdf')
        },
        {
          name: 'ATRenew ESG Committee Operating Mechanism',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2ESGC%E8%BF%90%E4%BD%9C%E6%9C%BA%E5%88%B6_2024.pdf')
        }
      ]
    },
    {
      classes: 'Business Ethics',
      content: [
        {
          name: 'Code of Business Conduct and Ethics',
          url: 'https://www.sec.gov/Archives/edgar/data/1838957/000119312521176950/d893059dex991.htm'
        },
        {
          name: 'Integrity and Self-discipline Guideline',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2%E5%BB%89%E6%B4%81%E8%87%AA%E5%BE%8B%E5%87%86%E5%88%99-202405.pdf'),
        },
        {
          name: 'Whistleblower Protection and Reward System',
          url: ossImg('/downloads/%E4%B8%87%E7%89%A9%E6%96%B0%E7%94%9F%E9%9B%86%E5%9B%A2%E4%B8%BE%E6%8A%A5%E4%BA%BA%E4%BF%9D%E6%8A%A4%E4%B8%8E%E5%A5%96%E5%8A%B1%E5%88%B6%E5%BA%A6-202404.pdf'),
        },
        {name: 'Member of Trust and Integrity Enterprise Alliance(阳光诚信联盟)',},
      ]
    },
    {
      classes: 'Sustainable Supply Chain',
      content: [
        {
          name: 'Supplier Code of Conduct',
          url: ossImg('/downloads/ATRenew_Supplier_Code_of_Conduct_EN.pdf')
        },
      ]
    },
    {
      classes: 'Certificates',
      content: [
        {
          name: 'ISO 14001: Environmental management systems',
          url: ossImg('/downloads/ISO_14001.pdf')
        },
        {
          name: 'ISO 27001: Information security management systems',
          url: ossImg('/downloads/ISO_27001.pdf')
        },
        {
          name: 'ISO 9001: Quality management system',
          url: ossImg('/downloads/ISO_9001.pdf')
        },
        {
          name: 'ISO 45001: Occupational health and safety management system',
          url: ossImg('/downloads/ISO_45001.pdf')
        },
        {
          name: 'ISO 20000: Information technology service management',
          url: ossImg('/downloads/ISO_20000.pdf')
        },
      ]
    },
    {
      classes: 'Responsible Marketing',
      content: [
        {
          name: 'Responsible Marketing Policy',
          url: ossImg('/downloads/ATRenew_Responsible_Marketing_Policy.pdf')
        },
      ]
    },
  ]
})

const data = {
  CN,
  US
}
export default data
